import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import styled from 'styled-components';
import './App.css';
import logo from './assets/sv_logo_multi.png';
import { Link } from 'react-router-dom';


export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: auto;
`;

export const StyledInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const StyledError = styled.div`
  color: red;
  margin: 10px auto 0px auto;
  font-size:0.85em;
  width:80%;
  padding-top:10px;
  text-align:left;
  position:relative;
  left:-10px;
`;


const EmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const EmailForm = () => {
  return (
    <div className="form-wrapper">
        <img src={logo} alt="SportyVentures Logo"/>
        <h1 className="header">For AI and productivity enthusiasts</h1>
        <div className="subtitle">Join the newsletter for updates on latest AI developments.</div>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={EmailSchema}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            // Sending form data to the server using axios
            const response = await axios.post('/subscribe', values);
            console.log('Server Response:', response.data);
            setStatus({ success: true });
          } catch (error) {
            console.error('Error submitting form:', error);
            setStatus({ success: false });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
              {status && status.success ? (
              <div className="subscribed">Thank you for subscribing!</div>
            ) : (
              status && <div className="not-subscribed">Oops! Something went wrong.</div>
            )}
            <label className="email-label" htmlFor="email">Email</label>
            <Field type="email" name="email" />
            <ErrorMessage name="email" component={StyledError} />
            <button className="button" type="submit" disabled={isSubmitting}>
              Join Newsletter
            </button>
              <div className="terms">
                  {/* Links to Terms of Use and Privacy Policy */}
                  By submitting this form, you agree to our{' '}
                  <Link to="/terms-of-service">Terms of Service</Link> and{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
              </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmailForm;
